<template>
  <div>
    {{ this.datasAtivas() }}
    <div v-if="carregando" style="text-align:center;">
      <pm-ProgressSpinner />
    </div>
    <div class="p-fluid formgrid grid" style="margin-top:-20px;" v-else="carregando">
      <div class="field col-12 md:col-12 lg:col-12" >
          <div class="pm-card card-resp" >
            <div class="p-datatable-wrapper table-responsive" >
              <table role="table" class="p-datatable-table">
                <thead class="p-datatable-thead" role="rowgroup">
                  <tr role="row" >
                    <th class="thead_column col-4" tabindex="0" role="cell" aria-sort="none" style="font-size: 14px; vertical-align: middle !important;">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Nome</span>
                      </div>
                    </th>

                    <th class="thead_column" v-for="(item , index) of presencasAlunosView[0].tableColuna" :key="index"   style="font-size: 14px; vertical-align: middle !important;text-align:center;">
                      <div class="p-column-header-content" >
                        <span class="p-column-title">{{item}}</span>
                      </div>
                    </th>

                    <th class="thead_column" style="font-size: 14px; vertical-align: middle !important;text-align:center;">
                      <div class="p-column-header-content" >
                        <span class="p-column-title">Total</span>
                      </div>
                    </th>
                  </tr>

                  <tr class="thead_column" style="text-align: center;">
                    <td> - - </td>
                    <td v-for="it in datas_ativas" :key="it">
                      <span class="p-column-title">
                          <pm-Button
                            title="Apagar presenças do dia."
                            type="button"
                            @click="deletarPresenca(it)"
                            label=""
                            icon="pi pi-trash"
                            class="p-button-danger p-button-sm btn-color table_style"
                            style="margin-left: 5px; width: 0px !important;height: 26px !important;"
                            >
                          </pm-Button>
                      </span>
                    </td>
                    <td> --  </td>
                  </tr>
                </thead>

                <tbody class="p-datatable-tbody" role="rowgroup">


                  <tr class="body_column" v-for="(item , index) of presencasAlunosView" :key="index" >
                    <td style="text-align:center;" >
                      <span class="size-presenca" style="white-space: pre;" >
                        {{item.nome}}
                        <span class="badge bg-danger table_style ml-2"  v-if="item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Transferido'">Transferido - {{item.evento_saida[item.evento_saida.length - 1].data_formato_br}}</span>
                        <span class="badge bg-danger table_style ml-2"  v-if="item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Evadido'"  >Evadido</span>
                        <span class="badge bg-danger table_style ml-2"  v-if="item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Faleceu'"  >Falecido</span>

                        <div v-if="item.evento_saida">
                          <!--<va-popover style="cursor: pointer;" class="badge bg-danger table_style ml-2"
                            :message="'Mudou para turma: ' + item.evento_saida[item.evento_saida.length-1].turma_nova" color="danger"
                            v-if="item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma'">
                            Mudou de turma - {{item.evento_saida[item.evento_saida.length - 1].data_formato_br}}
                          </va-popover>-->
                          <va-popover style="cursor: pointer;" class="badge bg-danger table_style ml-2"
                          :message="'mudou de turma'" color="danger"
                            v-if="item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma'">
                            Mudou de turma - {{item.evento_saida[item.evento_saida.length - 1].data_formato_br}}
                          </va-popover>
                        </div>
                        <!--Verificar se ele entrou na turma-->
                        <!-- <span class="badge bg-danger table_style ml-2 hide-presenca" v-if="!item.entrou_no_prazo_certo && item.data_entrada_turma_br != ''"> Matriculado em : {{item.data_entrada_turma_br}}</span> -->

                      </span>
                    </td>
                    <td v-for="n in item.presencas1" :key="n" role="cell" style="font-size:12px; vertical-align: middle !important;text-align:center;">
                      <div v-if="n.presenca == undefined">
                          <pm-button title="O aluno não possui presença neste dia pois não estava na turma!" disabled="true"  style="cursor: pointer;padding-left: 7px;"> - </pm-button>
                      </div>
                      <div v-if="n.presenca == 1" style="color:#13b527">
                        <pm-button  title="Editar" style="cursor: pointer;" @click="dialogActionEditar(item.nome,n, 1)">
                          <p style="font-size:14px;" v-if="paraImprimir">P</p>
                          <span v-else  class="badge bg-success infoTabela" >P</span>
                        </pm-button>
                      </div>
                      <div v-if="n.presenca == 0 && n.justificada_falta == 0" style="color: #df1212;">
                        <pm-button  title="Justificar falta" style="cursor: pointer;" @click="dialogActionEditar(item.nome,n, 0)">
                            <p style="font-size:14px;" v-if="paraImprimir">F</p>
                            <span v-else  class="badge bg-danger infoTabela" >F</span>
                        </pm-button>
                      </div>
                      <div v-if="n.presenca == 0 && n.justificada_falta == 1" style="color:#e97d20;">
                        <pm-button  title="Editar" style="cursor: pointer;" @click="dialogActionFaltaJustificada(item.nome, n)">
                            <p style="font-size:14px;" v-if="paraImprimir">FJ</p>
                            <span v-else  class="badge bg-warning infoTabela">FJ</span>
                        </pm-button>
                      </div>
                    </td>
                    <td style="color:#155ad9; text-align:center;" v-if="paraImprimir">
                      <div>
                        {{item.cntPresencas}}
                      </div>
                    </td>
                    <td style="color:#155ad9; font-size:12px; vertical-align: middle !important; text-align:center;" v-if="!paraImprimir">
                      <div>
                        <pm-button  title="Total de presenças" style="cursor: pointer;">
                            <span  class="badge bg-primary" style="font-size:12px;">{{item.cntPresencas}}</span>
                        </pm-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <pm-Dialog :header="nomeTipo" v-model:visible="displayMaximizableEdit" :style="{width: '100vw'}" :maximizable="true" :modal="true">
            <div class="field col-12" >
              <div>
                 <button  class="btn btn-info" v-if="podeEditar == 0" @click="this.desativado = !this.desativado" style="margin-bottom:15px;">liberar Edição Para Falta</button>
                 <button  class="btn btn-primary" v-if="faltaJustificadaDialog" @click="openArquivos">Visualizar arquivos já adicionados</button>
                 <pm-DataTable v-if="!faltaJustificadaDialog" :value="item_edicao"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
                   responsiveLayout="scroll">

                    <pm-Column field="data"  header="Nome" class="size-tb-modal-presenca" >
                      <template #body="{data}">
                        <div>
                          {{data.nome}}
                        </div>
                      </template>
                    </pm-Column>
                    <pm-Column field="data" header="PRESENTE" style="font-size: 14px;vertical-align:middle !important">
                      <template #body="{data}" >
                        <div>
                          <div class="form-check" v-if="acesso.editar">
                            <label
                              class="form-check-label"
                              style="color: #066e4f; font-weight: 900"
                            >
                              <!--<input
                                v-if="podeEditar != 1"
                                :disabled="podeEditar != 1"
                                type="radio"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 1"
                              />-->
                              <input
                                v-if="podeEditar != 1"
                                type="radio"
                                :disabled="desativado"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 1"
                                @click="editarPresencaDiaMarcado(1, data)"
                              />
                              <input
                                v-if="podeEditar == 1"
                                :disabled="!podeEditar"
                                type="radio"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 1"
                                @click="editarPresencaDiaMarcado(1, data)"
                              />
                            </label>
                          </div>
                          <div v-else>
                            <div class="alert alert-info">
                              Usuário Não Autorizado
                            </div>
                          </div>
                        </div>
                      </template>
                    </pm-Column>
                    <pm-Column field="data" header="AUSENTE" style="font-size: 14px;vertical-align:middle !important">
                      <template #body="{data}">
                        <div>
                          <div class="form-check" v-if="acesso.editar">
                            <label
                              class="form-check-label"
                              style="color: #f90d0d; font-weight: 900"
                            >
                              <!--<input
                                v-if="podeEditar != 1"
                                :disabled="podeEditar != 1"
                                type="radio"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 0"
                              />-->
                              <input
                                v-if="podeEditar != 1"
                                type="radio"
                                :disabled="desativado"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 0"
                                @click="editarPresencaDiaMarcado(0, data)"
                              />
                              <input
                                v-if="podeEditar"
                                type="radio"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 0"
                                @click="editarPresencaDiaMarcado(0, data)"
                              />
                            </label>
                          </div>
                          <div v-else>
                            <div class="alert alert-info">
                              Usuário Não Autorizado
                            </div>
                          </div>

                        </div>
                      </template>
                    </pm-Column>
                 </pm-DataTable>
                 <!-- tabela arquivos -->
                 <br>
                 <button  class="btn btn-primary" @click="editarPresencaDia()" v-if="(acesso.editar && podeEditar == 1) || !this.desativado " >Editar Presença</button>
                 &nbsp
                 <button  class="btn btn-primary" @click="limparCampos" v-if="acesso.editar && podeEditar == 0">Justificar ausência</button>
              </div>
            </div>

            <div class="p-fluid formgrid grid" v-if="podeEditar == 2">
              <h6>Justificar falta</h6>
              <div class="field col-12 md:col-12">
                  <label for="ativiade">Informe a justificativa(motivo do aluno não ter participado da aula):</label>
                  <pm-Textarea   :disabled="displayMaximizableEdit1"  rows="6" @change="validateForm" v-model="justificativaDoAluno.justificacao_conteudo" :class="{'p-invalid': validationErrors.justificacao_conteudo  && submitted}" />
                  <small v-show="validationErrors.justificacao_conteudo && submitted" class="p-error">A justificativa é obrigatória!</small>
              </div>
              <div class="field col-12 md:col-12">
                    <label for="arquivo">Arquivo que comprove a justificativa(não é obrigatório):</label>
                    <va-file-upload

                      upload-button-text="Adicionar arquivo"
                      :disabled="displayMaximizableEdit1"
                      :undo="false"
                      v-model="justificativaDoAluno.justificativa_arquivo"
                      file-types=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"
                    />
              </div>
              <button  class="btn btn-primary" :disabled="botaoDisabled" v-if="!faltaJustificadaDialog" @click="acaoJustificarAusencia">Enviar justificativa</button>
              <button  class="btn btn-primary" :disabled="botaoDisabled || displayMaximizableEdit1" v-if="faltaJustificadaDialog"  @click="acaoJustificarAusencia">Editar justificativa</button>
            </div>
          </pm-Dialog>
          <!--tabela de arquivos -->
          <pm-Dialog  header="Arquivos do aluno" v-model:visible="displayMaximizableEdit1"  :maximizable="true">
             <pm-DataTable v-if="faltaJustificadaDialog" :value="listaDeArquivoAluno"   dataKey="id" :rowHover="true" filterDisplay="menu"
              responsiveLayout="scroll">
              <pm-Column field="data"  header="Nome" class="size-tb-modal-presenca">
                <template #body="{data}">
                  <div>
                      {{data.nome}}
                  </div>
                </template>
              </pm-Column>
              <pm-Column field="data" header="Ações" style="font-size: 14px;vertical-align:middle !important">
                <template #body="{data}" >
                  <pm-Button title="Visualizar arquivo" type="button"  @click="visualizarArquivoModal(data.arquivo)" label="Visualizar documento"
                      icon="pi pi-file" class="p-button-primary p-button-sm btn-color;table_style" style="margin-left:5px">
                  </pm-Button>
                  <pm-Button :disabled="displayMaximizableEdit2" title="Apagar arquivo" type="button"  @click="deletarArquivo(data)" label="Apagar"
                      icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color;table_style" style="margin-left:5px">
                  </pm-Button>
                </template>
              </pm-Column>
            </pm-DataTable>
          </pm-Dialog>

          <pm-Dialog position="topright" header="Documento" v-model:visible="displayMaximizableEdit2" :style="{width: '100vw', height: '50vw'}" :maximizable="true">
             <div class="col-md-12">
                  <iframe :src="arquivosView" width="100%" height="1000px"></iframe>
              </div>
          </pm-Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { Presenca } from "@/class/presenca";
import { Aluno } from "@/class/alunos";
import axios from 'axios';

import Util from "@/utils/rota";

export default {
  name: "TableProfPresenca01",
  props: {
    presencasAlunosView: { type: Array, default: () => [] },
    mes:null,
    mes_sel:null,
    mes_nome:null,
    etapaDeEnvioDePresenca:null,
    acesso:[],
  },
  data () {
    return {
      carregando:true,
      datas_ativas:[],
      botaoDisabled:false,
      dataSelecionada:'',
      arquivosView:'',
      aluno_id:'',
      anoUsado:'',
      pathDoSite: axios.defaults.baseURL.replace('api/v1',''),
      displayMaximizableEdit:false,
      displayMaximizableEdit1:false,
      displayMaximizableEdit2:false,
      item_edicao:[],
      item_nome_edicao:null,
      marcado_item:[],
      podeEditar:1,
      nomeTipo:'',
      justificativaDoAluno: {
        justificativa_arquivo:'',
        justificacao_conteudo:'',
      },
      submitted:false,
      validationErrors: {},
      paraImprimir:false,
      faltaJustificadaDialog:false,
      listaDeArquivoAluno:[],
      desativado:1,
      funcao:0,
    }
  },
  async beforeMount(){
    await this.logado();
    await this.datasAtivas()
    this.carregando = false;
  },
  methods: {
    datasAtivas(){
      this.datas_ativas = []
      var df;
      for (const it of this.presencasAlunosView) {
        df = it.presencas1;
        break
      }
      this.datas_ativas = df
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    pegarANumeracao() {
      if (this.listaDeArquivoAluno.length) {
        this.listaDeArquivoAluno.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
        let arquivo =  this.listaDeArquivoAluno[this.listaDeArquivoAluno.length - 1];
        //Receber o numero do arquivo, ex: Justificativa_de_Falta_Documento_4
        let receberNumeracaoDoArquivo = arquivo.nome.slice(-1);
        return parseInt(receberNumeracaoDoArquivo) + 1;
      }
      else {
        return 1;
      }
    },
    visualizarArquivoModal(arquivo) {
      this.arquivosView = arquivo;
      this.displayMaximizableEdit2 = true;
      this.desativado = 1;
    },
    async buscarArquivos() {
      let dataEnvia = this.item_edicao.data.data;
      let data1 = await Aluno.buscarArquivoAluno(this.aluno_id, "justificarFalta", dataEnvia);
      this.listaDeArquivoAluno = [];
      for (const el of data1.data) {
        let novo = {
          id:el.id,
          nome: el.nome,
          path: el.path,
          aluno_id: el.aluno_id,
          arquivo: this.pathDoSite + 'storage/'+el.path,
        }
        this.listaDeArquivoAluno.push(novo);
      }
    },
    openArquivos() {
      this.buscarArquivos();
      if (this.listaDeArquivoAluno.length) {
        this.displayMaximizableEdit1 = !this.displayMaximizableEdit1;
      }
      else {
        alert("Não existem arquivos cadastrados!");
      }
    },
    async deletarArquivo(data) {
      let novo = {
        aluno_id: data.aluno_id,
        id: data.id
      }
      try {
        if (window.confirm("Você realmente deseja apagar o arquivo?")) {
          let data = await Aluno.deletarArquivo(novo);
          await this.buscarArquivos();
          //Fechar modal se nao tiver arquivo
          if (!this.listaDeArquivoAluno.length) {
            this.displayMaximizableEdit1 = false;
          }
        }
      }
      catch(e) {
        alert("Problema ao apagar o arquivo!");
      }
    },
    async dialogActionFaltaJustificada(nome, item, n) {
      this.limparCampos();
      this.item_nome_edicao = nome;
      let items = {
        data: item,
      };
      this.aluno_id = items.data.aluno_id;
      this.item_edicao = items;
      this.nomeTipo = "Falta justificada";
      this.podeEditar = 2;
      let data = await Presenca.buscarPresenca(item.presenca_id);
      this.justificativaDoAluno.justificacao_conteudo = data.data.justificacao_conteudo;
      //receber o ano da data
      let ano = data.data.data.split(" ");
      ano = ano[0];
      ano = ano.split('-');
      ano = ano[0];
      this.anoUsado = ano;
      this.aluno_id = item.aluno_id;
      //buscar os arquivos do aluno
      await this.buscarArquivos();
      this.faltaJustificadaDialog = true;
      this.displayMaximizableEdit = true;
    },
    limparCampos() {
      this.podeEditar = 2;
      this.justificativaDoAluno.justificativa_arquivo = '';
      this.justificativaDoAluno.justificacao_conteudo = '';
      delete this.validationErrors['justificacao_conteudo'];
    },
    validateForm() {
        if (this.justificativaDoAluno.justificacao_conteudo == null || this.justificativaDoAluno.justificacao_conteudo == '')
            this.validationErrors['justificacao_conteudo'] = true;
        else
            delete this.validationErrors['justificacao_conteudo'];
        return !Object.keys(this.validationErrors).length;
    },
    async acaoJustificarAusencia() {
      this.submitted = true;
      if (this.validateForm() && this.submitted) {
        let novo = {
          presenca_id:  this.item_edicao.data.presenca_id,
          justificada_falta: 1,
          justificacao_conteudo: this.justificativaDoAluno.justificacao_conteudo
        }
        //Justificar a falta
        try {
          let data = await Presenca.justificarAFalta(novo);
        }
        catch(e) {
        }
        //adicionar arquivo
        if (this.justificativaDoAluno.justificativa_arquivo != '') {
          //enviar arquivos
          try {
            var arquivoNumeracao = this.pegarANumeracao();
            let dataEnvia = this.item_edicao.data.data;
            this.botaoDisabled = true;
            for (var i = 0; i < this.justificativaDoAluno.justificativa_arquivo.length; i++) {
              let formData = new FormData();
              formData.append('documento_auxiliar', this.justificativaDoAluno.justificativa_arquivo[i]);
              formData.append('nome', "Justificativa_de_Falta_Documento_"+String(arquivoNumeracao++));
              formData.append('local', "justificarFalta");
              formData.append('data', dataEnvia);
              let data1 = await Aluno.cadastrarArquivo(this.item_edicao.data.aluno_id, formData);
            }
            this.botaoDisabled = false;
          }
          catch(e) {
            this.$vaToast.init({
              message: "Problema ao adicionar o arquivo!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color:'danger',
              duration: 2500,
              fullWidth: false,
            })
            this.botaoDisabled = false;
          }
        }
        this.$vaToast.init({
          message: "Justitifcativa enviada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.test(this.mes_sel,this.mes_nome);
        this.displayMaximizableEdit = false;
      }
    },
    dialogActionEditar(nome,item, podeEditar){
      this.faltaJustificadaDialog = false;
      this.displayMaximizableEdit = true;
      this.item_nome_edicao = nome;
      this.item_edicao = item;
      this.item_edicao["nome"] = this.item_nome_edicao;
      let items = {
        data: this.item_edicao
      };
      this.item_edicao = [];
      this.item_edicao = items;
      this.podeEditar = podeEditar;
      if (podeEditar) {
        this.nomeTipo = "Editar presença";
      }
      else {
        this.nomeTipo = "Justificar ausência";
      }
    },
    editarPresencaDiaMarcado(check,item){
      this.marcado_item = item;
      this.marcado_item["check"] = check;
    },
    async editarPresencaDia(){
        let playload = {
          presenca: this.marcado_item.check
        }
      console.log("dadasqqqqdasds", this.marcado_item);
      let data = await Presenca.editarPresencaOne(playload, this.marcado_item.presenca_id);

      this.test(this.mes_sel,this.mes_nome);
      this.displayMaximizableEdit = false;
    },
    async test(mes, mesExibir){
      this.$emit('test', mes, mesExibir);
    },
    async deletarPresenca (dados){
      console.log(dados)
      if(this.funcao == 1 || this.funcao == 2){
        let res = await axios.delete('presencas/apagar-dia/turma/'+dados.turma_id,{
          params: {
            data: dados.data,
            disciplina: dados.disciplina_id,
            segmento_id: dados.segmento_id,
            escola_id: dados.escola_id
          }
        });

        this.datasAtivas();
        this.test(this.mes_sel,this.mes_nome);
      }else{
        let dat = dados.data.split(' ')
        let ano = dat[0].split('-');

        let res = await axios.delete('presencas/apagar-dia/turma/'+dados.turma_id+'/professor',{
          params: {
            data: dados.data,
            disciplina: dados.disciplina_id,
            segmento: dados.segmento_id,
            ano:ano[0]
          }
        });

        this.datasAtivas();
        this.test(this.mes_sel,this.mes_nome);
      }
    }
  }
};
</script>

<style scoped>

.thead_column:hover {
    background: #e9ecef;
    color: #495057;
}
.thead_column {
    text-align: left;
    padding: 1rem 0.7rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.body_column td{
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

.infoTabela {
  font-size:12px!important;
  font-weight:normal!important;
}

.abrirModalFullScreen {
  width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	left: 0 !important;
	top: 0 !important;
}

.card-resp{
  padding: 8px;
}

</style>

<style>
  .va-file-upload .va-file-upload__field .va-file-upload__field__button {
    border-radius: 4px!important;
  }
</style>


